import React from 'react'
import "./Pricing.scss";
export const Pricing = (props) => {
    return (
        <div id="pricing" classname="pricing-container">
            <div class="section-title text-center">
                <span class="sub-title">{props?.data?.MainHeading}</span>
                <p >Choose Your Best Plan</p>
            </div>
            <div className="pricing-table">
                <div className="pricing-card">
                    <h3 className="pricing-card-header">Starter Plan</h3>
                    <div className="price"><sup>$</sup>29<span>/MO</span></div>
                    <ul>
                        <li>Basic POS functionalities</li>
                        <li>Sales tracking</li>
                        <li>Inventory management</li>
                        <li>Customer management</li>
                        <li>Basic reporting and analytics</li>
                        <li>Email support</li>
                        <li>One user account</li>
                        <li>Regular updates </li>
                    </ul>
                    <a href="#" className="order-btn">Order Now</a>
                </div>
                <div className="pricing-card">
                    <h3 className="pricing-card-header">Value Plan</h3>
                    <div className="price"><sup>$</sup>39<span>/MO</span></div>
                    <ul>
                        <li>All Basic Package features</li>
                        <li>Advanced Reporting & Analytics</li>
                        <li>Priority Email Support</li>
                        <li>Up to 5 User Accounts</li>
                        <li>24/7 Customer Support</li>
                        <li>Loyalty program management</li>
                        <li>Multi-location support</li>
                        <li>Employee Performance Tracking</li>
                    </ul>
                    <a href="#" className="order-btn">Order Now</a>
                </div>

                <div className="pricing-card">
                    <h3 className="pricing-card-header">Elite Plan</h3>
                    <div className="price"><sup>$</sup>59<span>/MO</span></div>
                    <ul>
                        <li>All standard features</li>
                        <li>Reporting & Analytics</li>
                        <li>Dedicated Account Manager</li>
                        <li>Unlimited User Accounts</li>
                        <li>Customizable Interface</li>
                        <li>3rd Party App Integration</li>
                        <li>Advanced Security Features</li>
                        <li>Training & Onboarding</li>
                    </ul>
                    <a href="#" className="order-btn">Order Now</a>
                </div>

                <div className="pricing-card">
                    <h3 className="pricing-card-header">Custom Package</h3>
                    <div className="custome-price"> Upon Quote</div>
                    <ul>
                        <li>Tailored Features for Your Business</li>
                        <li>Fully Customizable Interface</li>
                        <li>Unlimited User Accounts</li>
                        <li>Training and Onboarding</li>
                        <li>Dedicated Account Manager</li>
                        <li>3rd Party App Integration</li>
                        <li>Advanced Security Features</li>
                        <li>AI-Powered Insights & Forecasting</li>
                    </ul>
                    <a href="#" className="order-btn">Order Now</a>
                </div>

            </div>
        </div>
    )
}



